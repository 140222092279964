(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/onetrust/assets/javascripts/onetrust.js') >= 0) return;  svs.modules.push('/components/components/onetrust/assets/javascripts/onetrust.js');

'use strict';

(() => {
  if (!svs.components.onetrust.data.enabled) {
    return;
  }
  const logger = svs.core.log.getLogger('component:onetrust');

  window.OptanonWrapper = () => {
    if (!window.OneTrust.IsAlertBoxClosed() && svs.components.onetrust.data.att === true) {
      if (location.pathname.startsWith('/logga-in/poker')) {
        window.OneTrust.AllowAll();
      }
    } else if (!window.OneTrust.IsAlertBoxClosed() && svs.components.onetrust.data.att === false) {
      if (location.pathname.startsWith('/logga-in/poker')) {
        window.OneTrust.RejectAll();
      }
    }
    window.OneTrust.OnConsentChanged(consent => {
      let optanonPerformance = false;
      let optanonPersonalization = false;
      try {
        optanonPerformance = consent.detail.indexOf('C0002') !== -1;
        optanonPersonalization = consent.detail.indexOf('C0003') !== -1;
      } catch (e) {
        logger.warn('Unable to parse Onetrust cookie "OptanonConsent"');
      }
      const analyticsConsent = new svs.components.analytics.customEvents.AnalyticsConsentChanged(optanonPerformance);
      analyticsConsent.track();
      const personalizedConsent = new svs.components.analytics.customEvents.PersonalizedConsentChanged(optanonPersonalization);
      personalizedConsent.track();
      setTimeout(() => {
        location.reload();
      }, 500);
    });
  };
})();

 })(window);